<template>
  <div class="history-page">
    <el-dialog title="历史处方" :visible.sync="visible" width="1200">
      <div class="prescription-record-list" v-loading="loading">
        <div class="list-item" v-for="(item, index) in dataList" :key="index">
          <div class="header">
            <!-- <div class="prescription-name" v-if="item.visitTime">
              {{ item.visitTime
              }}<span v-if="item.visitTypeName"
                >【{{ item.visitTypeName }}】</span
              >
            </div> -->
            <!-- <div class="prescription-name-2" v-else></div> -->
            <div>开方时间：{{item.openingTime}}</div>
            <div class="drug-title">中药处方{{ index + 1 }}</div>
            <div>
              <el-button type="primary" @click="importData(item)"
                >选择处方</el-button
              >
            </div>
          </div>
          <div class="drug-name">
            <div class="name-list">
              <div
                class="name-item"
                v-for="(n, i) in item.mbglPrescriptionDrugsList"
                :key="i"
              >
                <div class="name">{{ n.drugsName }}</div>
                <div class="unit">{{ n.drugsDose }}{{ n.drugsUseUnit }}</div>
              </div>
            </div>
            <div class="take-drug">
              <span>服药频次：{{ item.frequency }}</span>
              <span>单剂次数：{{ item.singleDoses }}</span>
              <span>总量：{{ item.total }}</span>
            </div>
            <div class="demand">
              服务要求：{{
                item.medicationRequirements ? item.medicationRequirements : "-"
              }}
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import qs from "qs";
export default {
  data() {
    return {
      searchValue: "",
      dataList: [],
      visible: false,
      loading: false,
      itemKey: 0,
      currentChoseList: [], //当前选择协定
      prescriptionRecordInfo: {},
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, //总页数
    };
  },
  methods: {
    init(obj) {
      this.visible = true;
      this.getList();
      this.prescriptionRecordInfo = obj;
    },
    getList() {
      this.currentChoseList = [];
      let params = {
        intParam1: this.pageSize,
        intParam2: this.pageNo,
        stringParam2: localStorage.currentTeam
          ? JSON.parse(localStorage.currentTeam).id
          : "",
        intParam4: 1,
        stringParam4: JSON.parse(localStorage.patientInfo).patience_id,
      };
      this.loading = true;
      this.$httpAes({
        url: this.$http.adornUrl(
          "/mbglPrescriptionRecords/getMbglPrescriptionRecords"
        ),
        method: "post",
        data: params,
      }).then(({ data }) => {
        if (data.status) {
          this.dataList = data.data;
          this.totalPage = data.totalCount;
          this.loading = false;
          if (this.dataList.length) {
            this.dataList.forEach((ele, index) => {
              ele.checked = false;
              ele.index = index;
              if (ele.agreementDrugs && ele.agreementDrugs.length) {
                let arr = ele.agreementDrugs.map((chr) => {
                  return (
                    chr.drugsName + `(${chr.drugsDose + chr.drugsUseUnit})`
                  );
                });
                if (arr.length) {
                  ele.drugsNames = arr.join("；");
                }
              }
            });
          }
        }
      });
    },
    handelChange(val) {
      this.getList();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getList();
    },
    // 选择协定方
    importData(item) {
      this.$emit("addRecipelItem", item);
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.history-page {
  /deep/.el-dialog {
    width: 1200px !important;
  }
  .prescription-record-list {
    .list-item {
      border-bottom: 1px solid #e8e8e8;
      margin-bottom: 32px;
      .header {
        height: 54px;
        background: #fafafa;
        border-radius: 4px 4px 0px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        .prescription-name {
          font-weight: 500;
          font-size: 14px;
          color: #666666;
        }
        .prescription-name-2 {
          width: 138px;
        }
        .drug-title {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
    .drug-name {
      padding: 2px 25px 0 16px;
      color: #666;
      .name-list {
        overflow: hidden;
        width: 100%;
        .name-item {
          float: left;
          width: 20%;
          margin-right: 5%;
          height: 44px;
          &:last-child {
            margin-right: 0;
          }
          display: flex;
          // justify-content: space-between;
          padding-top: 10px;
          .name {
            width: 112px;
            padding: 0;
          }
          .unit {
            padding-left: 8px;
          }
        }
      }
      .take-drug {
        padding-top: 24px;
      }
      .demand {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }
}
</style>
