<template>
  <div class="addvisit">
    <el-dialog
      title="编辑访视记录"
      @close="closeDailog"
      :visible.sync="visible"
    >
      <el-form :model="ruleForm" :rule="rules" ref="ruleForm">
        <el-form-item label="访视类型" prop="visitType" required>
          <!-- <span v-if="first">初诊</span> -->
          <el-radio-group v-model="ruleForm.visitType">
            <el-radio v-for="item in typeList" :label="item.value" :key="item.value">{{
              item.name
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="访视日期" prop="visitTime" required>
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="ruleForm.visitTime"
            type="date"
            placeholder="请选择"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          style="margin-bottom: 5px !important"
          label="访视表单"
          required
        >
          <el-container>
            <el-aside
              style="border: 1px solid #eeeeee; margin-right: 5%"
              width="50%"
            >
              <div class="qxzyfs">请选择要发送的内容</div>
              <el-input
                placeholder="请输入关键字查询"
                v-model="searchValue"
                style="
                  width: 250px;
                  height: 30px;
                  margin-left: 20px;
                  margin-bottom: 10px;
                "
                class="input-with-select"
              >
                <el-button
                  slot="append"
                  @click="gettreeDate()"
                  style="height: 30px"
                  icon="el-icon-search"
                ></el-button>
              </el-input>
              <OrgTree
                v-if="visible"
                ref="tree"
                @refreshDataList="getList"
                :content="content"
                :type="type"
                v-bind="$attrs"
                :defaultList="itemList"
              ></OrgTree>
            </el-aside>
            <el-aside width="45%" class="right" style="">
              <div class="qxzyfs">已选择的内容</div>
              <el-table :data="itemList" style="width: 100%">
                <el-table-column prop="name" label="内容"></el-table-column>

                <el-table-column
                  width="100"
                  label="操作"
                  fixed="right"
                  align="right"
                  header-align="right"
                >
                  <template slot-scope="scope">
                    <img
                      @click="delContent(scope.row, scope.$index)"
                      style="width: 14px; height: 14px"
                      src="../../../../assets/img/fadel.png"
                      alt=""
                    />
                  </template>
                </el-table-column>
              </el-table>
            </el-aside>
          </el-container>
          <!-- <el-form>
		      <el-form-item>
		     
		      <div class="title">
		          <div class="name">问卷类别</div>
		          <div class="name">问卷名称</div>
		        </div>
		        <el-container style="height: 380px;width: 740px;" >
		      
		        <el-aside width="50%" class="left">
		      		<OrgTree v-if="visible" @refreshDataList="getList" :type='type' v-bind="$attrs"></OrgTree>
		      	</el-aside>
		      <el-aside width="50%" class='right' style=''>
		     
				  <el-checkbox-group v-model="item">
				      <el-checkbox   :disabled="item.disabled"  @change="setCheck" v-for='(item,index) in itemList' :key='index' :label='item.id'  style='height:28px;line-height:28px'>{{item.name}}</el-checkbox>
	
				    </el-checkbox-group>
		      	</el-aside>
		        </el-container>
		    
		      </el-form-item>
		    </el-form> -->
        </el-form-item>
      </el-form>

      <!-- <el-cascader-panel :options="options" :props='props' v-model='item' @expand-change='change'></el-cascader-panel> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="addVisitInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import OrgTree from "./tree2/tree";

import Qs from "qs";
export default {
  name: "chooseQuestion",
  props: {
    type: {
      //type限定子组件的类型，如果是数组就写arr，字段有特殊颜色，不然就是单词错了
      type: Number,
    },
    ruleFormDetail: {
      //type限定子组件的类型，如果是数组就写arr，字段有特殊颜色，不然就是单词错了
      type: Object,
    },
  },
  components: {
    OrgTree,
  },
  data() {
    return {
      first: false,
      typeList: [],
      radio: 7,
      searchValue: "",
      content: "",
      // 日期
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      ruleForm: {
        visitType: "",
        visitTime: "",
        infoId: "",
      },
      rules: {
        visitTime: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        visitType: [
          { required: true, message: "请选择访视类型", trigger: "change" },
        ],
      },
      // radio:'',
      visible: false,
      options: [],
      props: {
        // emitPath:false,
        lazy: true,
        value: "value",
        label: "name",
        lazyLoad: async (node, resolve) => {
          // console.log(node.value,type);
          const { level } = node;
          // console.log(level);
          if (level === 1) {
            if (this.type == 5) {
              const { data: res } = await this.$http({
                url: this.$http.adornUrl("/indexLibrary/list"),
                method: "get",
                params: {
                  contentType: node.value,
                },
              });
              let list = [];
              if (res.data.length) {
                this.itemList = res.data;
                list = Array.from(res.data).map((v) => ({
                  value: v.id,
                  name: v.libraryName,
                  leaf: level >= 1,
                }));
              } else {
                list = [
                  {
                    value: 123,
                    name: "暂无数据",
                    leaf: level >= 1,
                    disabled: true,
                  },
                ];
              }
              // console.log(list,'list');
              // console.log(res,'load res ');
              resolve(list);
            } else {
              const { data: res } = await this.$httpAes({
                url: this.$http.adornUrl("/missionaryWork/list"),
                method: "post",
                data: {
                  stringParam2: node.value,
                },
              });
              let list = [];
              if (res.data.length) {
                this.itemList = res.data;
                list = Array.from(res.data).map((v) => ({
                  value: v.id,
                  name: v.workName,
                  leaf: level >= 1,
                }));
              } else {
                list = [
                  {
                    value: 123,
                    name: "暂无数据",
                    leaf: level >= 1,
                    disabled: true,
                  },
                ];
              }
              // console.log(list,'list');
              // console.log(res,'load res ');
              resolve(list);
            }
          }
        },
      },
      item: [],
      itemList: [], //懒加载的item列表
      initType: "",
      flag: 1,
      isIncludeFirst: false, //是否含有初诊记录
      recordInfo: {},
      firstInfo: {},
    };
  },
  created() {
    this.initType = this.type;
    console.log(this.type, this.initType);
  },
  mounted() {
    if (this.first) {
      this.ruleForm.visitType = 0;
    }
    // this.init()
    this.getDictList();
    this.getDataList();
  },
  methods: {
    gettreeDate() {
      this.content = this.searchValue;
    },
    getDataList() {
      let that = this;
      let teamsId = "";
      let doctorId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        doctorId = JSON.parse(localStorage.userInfo).id;
      }
      let params = {
        stringParam1: teamsId,
       // doctorId: doctorId,
        stringParam2: JSON.parse(localStorage.patientInfo).patience_id,
      };

      that.dataListLoading = true;
      that
        .$httpAes({
          url: that.$httpAes.adornUrl("/visitInfo/list"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            if (data.data.length == 0) {
              console.log("that.dataList.length==0");
              that.first = true;
            } else {
              console.log("that.dataList.length!=0");
              that.first = false;

              data.data.forEach((ele) => {
                if (ele.visitType == 0) {
                  this.isIncludeFirst = true;
                  this.firstInfo = ele;
                }
              });
            }
          }
        });
    },
    setCheck(val) {
      console.log(val);
    },
    closeDailog() {
      this.$emit("closeeQs");
      // this.visible= false
    },
    delContent(item, index) {
      this.itemList.splice(index, 1);
      this.$refs.tree.setChecked(item);
    },
    change() {
      console.log("change", this.item);
      this.item = [];
    },
    getParentNode(n, arr) {
      // console.log('getParentNode','n',n,arr,'arr');
      if (n.parent && n.parent.data.name) {
        // console.log(n.parent.data.name,'n.parent.data.name',arr,'arr');
        arr.unshift(n.parent.data.value);
        this.getParentNode(n.parent, arr);
      } else {
        return arr;
      }
    },
    async getList(
      checkedNodes,
      checkedKeys,
      halfCheckedNodes,
      halfCheckedKeys
    ) {
      let arr = [];
      console.log("checkedKeys.checkedNodes", checkedKeys.checkedNodes);
      for (let s of checkedKeys.checkedNodes) {
        if (s.children == undefined) {
          arr.push(s);
        }
      }

      if (arr.length) {
        this.itemList = arr;
      } else {
        this.itemList = [{ value: 123, name: "暂无数据", disabled: true }];
      }
      //   let hospitalId=''
      //   if (localStorage.userInfo) {
      //     hospitalId = JSON.parse(localStorage.userInfo).deptPid;
      //   }
      //   let teamsId = "";
      //   let enertyId = "";
      //   if (localStorage.currentTeam) {
      //     teamsId = JSON.parse(localStorage.currentTeam).id;
      //     enertyId = JSON.parse(localStorage.currentTeam).entityId;
      //   }
      //   if(this.type==5){
      //           let type = ''
      //           let typeSec = ''
      //           if(d.type=='表单类别'){
      //             type=d.value
      //             typeSec=''
      //           }else{
      //             type=n.parent.data.value
      //             typeSec=d.value
      //           }
      //           const {data:res} = await this.$http({
      //             url:this.$http.adornUrl('/vfTableInfo/list'),
      //             method:'get',
      //             params:{
      //               tableModel:type,
      //               tableType:typeSec,
      //               tableBelongs:1,
      //   publishStatus: 1,
      //   isUse: 1,
      //               pageSize:999,
      //               pageNo:1,
      //               teamId:teamsId
      //             }
      //           })
      //           let list = []
      //           if(res.data.length){
      //             res.data.forEach(v=>{
      //               v.name = v.tableName
      //             })

      // this.itemList = res.data

      //           }else{
      //             this.itemList = [{value:123,name:'暂无数据',disabled:true}]
      //           }

      //         }else{
      //           let type = ''
      //           let typeSec = ''
      //           let arr  = ['','','','',''];
      //                         if(d){

      //             if(d.type=='宣教分类'){

      //               arr= [d.value,'']
      //             }else{

      //               arr = [n.data.value]
      //               this.getParentNode(n,arr)
      //             }
      //           }
      //           const {data:res} = await this.$http({
      //             url:this.$http.adornUrl('/missionaryWork/list'),
      //             method:'get',
      //             params:{
      //              workType:arr[0],
      //               workTypeSec:arr[1],
      //               workTypeThree:arr[2],
      //               workTypeFour:arr[3],
      //               workTypeFive:arr[4],
      //             }
      //           })

      //           if(res.data.length){
      //             res.data.forEach(v=>{

      //               v.name=v.workName
      //             })
      //             this.itemList = res.data

      //           }else{
      //             this.itemList = [{value:123,name:'暂无数据',disabled:true}]
      //           }

      //         }
    },
    // 获取来源列表
    getDictList() {
      let _this = this;
      _this
        .$httpAes({

          url: _this.$http.adornUrl("/dict/list?type=访视类型"),
          method: "post",
		  data: {
		    stringParam1: '访视类型',
		  
		  },
        })
        .then(({ data }) => {
          _this.typeList = data.data;
          _this.typeList.unshift({ value: 0, name: "初诊" });
        });
    },
    // async getType(dict){
    //   const {data} = await this.$http({
    //     url:this.$http.adornUrl('dict/getDictList'),
    //     method:'get',
    //     params:{
    //       type:dict,
    //       pageSize:999
    //     }
    //   })
    //   for (var i = 0; i < data.data.length; i++) {
    // 				// data.data[i].isEdit = false;
    // 				data.data[i].itemList = [];

    // 			}
    //   // console.log(res,'res')
    //   for (var i = 0; i < data.data.length; i++) {
    // 				if(data.data[i+1]&&data.data[i].type==data.data[i+1].type){
    // 					console.log(data.data[i].type,data.data[i].name);
    // 					if(data.data[i].type==data.data[i].name){
    // 						data.data[i].itemList.push(data.data[i+1])
    // 						data.data.splice(i+1,1)
    // 					}else{
    // 						data.data[i+1].itemList= []
    // 						// console.log(data.data[i+1],'before');

    // 						data.data[i+1].itemList.push(data.data[i])
    // 						// console.log(data.data[i+1],'after');
    // 						data.data.splice(i,1)

    // 					}
    // 				}

    // 			}
    //       console.log(data.data,'data.data');
    //   this.options=data.data
    // },

    addVisitInfo() {
      let params = {
        uuid: this.ruleForm.uuid,
        visitType: this.ruleForm.visitType,
        visitTime: this.ruleForm.visitTime,
      };
      if (this.firstInfo && this.firstInfo.uuid == this.recordInfo.uuid) {
      } else {
        if (this.ruleForm.visitType == 0 && this.isIncludeFirst) {
          this.$message.error("已有初诊记录，请重新选择类型！");
          return;
        }
      }
      if (this.itemList.length == 0) {
        this.$message.error("请选择问卷！");
        return;
      }
      let arr = [],
        arr2 = [];
      this.itemList.forEach((item) => {
        if (item && (item.id == 1 || item.id == 2)) {
          arr2.push(item);
        } else {
          arr.push(item.id);
        }
      });
      params.infoId = arr.join(",");
      params.teamsId = JSON.parse(localStorage.currentTeam).id;
      params.patienceId = JSON.parse(localStorage.patientInfo).patience_id;
      params.doctorId = JSON.parse(localStorage.userInfo).id;
      if (arr2.length) {
        let newArr = arr2.map((ele) => {
          return ele.id;
        });
        params.prescriptionType = newArr.join(",");
      }

      let _this = this;

      // console.log(arr2);
      // return;
      _this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (_this.flag == 1) {
            _this.flag = 0;
            _this
              .$httpAes({
                url: _this.$http.adornUrl("/visitInfo/update"),
                method: "post",
                data: params,
              })
              .then((data) => {
                if (data.data.status) {
                  _this.$message({
                    message: data.data.msg,
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      _this.closeDailog();
                      _this.flag = 1;
                    },
                  });
                  // if (arr2.length && data.data.data) {
                  //   let newArr = [];
                  //   arr2.forEach((ele) => {
                  //     let obj = {
                  //       // id: ele.id,
                  //       teamsId: JSON.parse(localStorage.currentTeam).id,
                  //       patientId: JSON.parse(localStorage.patientInfo)
                  //         .patience_id,
                  //       prescriptionRecordsId: ele.infoId,
                  //       prescriptionType: ele.value,
                  //       status: 3, //未开方
                  //       visitUuid: data.data.data,
                  //       infoId: ele.infoId,
                  //     };
                  //     newArr.push(obj);
                  //   });

                  //   if (newArr.length) {
                  //     if (
                  //       _this.ruleForm &&
                  //       _this.ruleForm.mbglPrescriptionRecords &&
                  //       _this.ruleForm.mbglPrescriptionRecords.length
                  //     ) {
                  //       if (
                  //         newArr.length ==
                  //         _this.ruleForm.mbglPrescriptionRecords.length
                  //       ) {
                  //         _this.$message({
                  //           message: data.data.msg,
                  //           type: "success",
                  //           duration: 1500,
                  //           onClose: () => {
                  //             _this.closeDailog();
                  //             _this.flag = 1;
                  //           },
                  //         });
                  //       } else {
                  //         // 原先数组和新数组比较有没有删除的处方记录
                  //         let deleteArr = [];
                  //         let ids = newArr.map((item) => item.infoId);
                  //         _this.ruleForm.mbglPrescriptionRecords.forEach(
                  //           (ele) => {
                  //             if (!ids.includes(ele.infoId)) {
                  //               deleteArr.push(ele.infoId);
                  //             }
                  //           }
                  //         );
                  //         // if (deleteArr.length) {
                  //         //   _this
                  //         //     .$http({
                  //         //       url: _this.$http.adornUrl(
                  //         //         "/mbglPrescriptionRecords/deleteByIds"
                  //         //       ),
                  //         //       method: "get",
                  //         //       params: { ids: deleteArr.join(",") },
                  //         //     })
                  //         //     .then((data) => {
                  //         //       if (data.data.status) {
                  //         //         // 如果存在记录信息就不用去重新新建处方
                  //         //         let existArr = [];
                  //         //         newArr.forEach((element) => {
                  //         //           if (element && !element.infoId) {
                  //         //             existArr.push(element);
                  //         //           }
                  //         //         });
                  //         //         if (existArr.length) {
                  //         //           this.addRecipelInfo(existArr);
                  //         //         } else {
                  //         //           _this.$message({
                  //         //             message: data.data.msg,
                  //         //             type: "success",
                  //         //             duration: 1500,
                  //         //             onClose: () => {
                  //         //               _this.closeDailog();
                  //         //               _this.flag = 1;
                  //         //             },
                  //         //           });
                  //         //         }
                  //         //       } else {
                  //         //         _this.flag = 1;
                  //         //         _this.$message({
                  //         //           message: data.data.msg,
                  //         //           type: "error",
                  //         //           duration: 1500,
                  //         //           onClose: () => {},
                  //         //         });
                  //         //       }
                  //         //     });
                  //         // } else {
                  //         //   let existArr = [];
                  //         //   newArr.forEach((element) => {
                  //         //     if (element && !element.infoId) {
                  //         //       existArr.push(element);
                  //         //     }
                  //         //   });
                  //         //   if (existArr.length) {
                  //         //     this.addRecipelInfo(existArr);
                  //         //   }
                  //         // }
                  //       }
                  //     } else {
                  //       // this.addRecipelInfo(newArr);
                  //       if (data.data.status) {
                  //         _this.$message({
                  //           message: data.data.msg,
                  //           type: "success",
                  //           duration: 1500,
                  //           onClose: () => {
                  //             _this.closeDailog();
                  //             _this.flag = 1;
                  //           },
                  //         });
                  //       } else {
                  //         _this.flag = 1;
                  //         _this.$message({
                  //           message: data.data.msg,
                  //           type: "error",
                  //           duration: 1500,
                  //           onClose: () => {},
                  //         });
                  //       }
                  //     }
                  //   }
                  // } else {
                  //   if (
                  //     _this.ruleForm &&
                  //     _this.ruleForm.mbglPrescriptionRecords &&
                  //     _this.ruleForm.mbglPrescriptionRecords.length
                  //   ) {
                  //     let deleteArr =
                  //       _this.ruleForm.mbglPrescriptionRecords.map((ele) => {
                  //         return ele.infoId;
                  //       });
                  //     console.log(
                  //       _this.ruleForm.mbglPrescriptionRecords,
                  //       deleteArr
                  //     );
                  //     _this
                  //       .$http({
                  //         url: _this.$http.adornUrl(
                  //           "/mbglPrescriptionRecords/deleteByIds"
                  //         ),
                  //         method: "get",
                  //         params: { ids: deleteArr.join(",") },
                  //       })
                  //       .then((data) => {
                  //         if (data.data.status) {
                  //           _this.$message({
                  //             message: data.data.msg,
                  //             type: "success",
                  //             duration: 1500,
                  //             onClose: () => {
                  //               _this.closeDailog();
                  //               _this.flag = 1;
                  //             },
                  //           });
                  //         } else {
                  //           _this.flag = 1;
                  //           _this.$message({
                  //             message: data.data.msg,
                  //             type: "error",
                  //             duration: 1500,
                  //             onClose: () => {},
                  //           });
                  //         }
                  //       });
                  //   } else {
                  //     _this.$message({
                  //       message: data.data.msg,
                  //       type: "success",
                  //       duration: 1500,
                  //       onClose: () => {
                  //         _this.closeDailog();
                  //         _this.flag = 1;
                  //       },
                  //     });
                  //   }
                  // }
                } else {
                  _this.flag = 1;
                  _this.$message({
                    message: data.data.msg,
                    type: "error",
                    duration: 1500,
                    onClose: () => {},
                  });
                }
              });
          }
        }
      });
    },
    // 给访视下面添加处方信息
    addRecipelInfo(list) {
      let _this = this;
      _this.ruleForm.mbglPrescriptionRecords.forEach((ele) => {
        list.forEach((chr) => {
          if (ele.prescriptionType == chr.prescriptionType) {
            chr.id = ele.infoId;
          }
        });
      });
	  
      _this
        .$http({
          url: _this.$http.adornUrl("/mbglPrescriptionRecords/adds"),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: list,
        })
        .then((data) => {
          if (data.data.status) {
            _this.$message({
              message: data.data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                _this.closeDailog();
                _this.flag = 1;
              },
            });
          } else {
            _this.flag = 1;
            _this.$message({
              message: data.data.msg,
              type: "error",
              duration: 1500,
              onClose: () => {},
            });
          }
        });
    },
    async init(obj) {
      var that = this;
      this.visible = true;
      this.recordInfo = obj;
      console.log("123345", obj);
      if (obj.visitType == 0) {
        this.first = true;
        this.ruleForm.visitType = 0;
      }

      let type = this.type;
      let dict = "";

      if (type === 5) {
        dict = "表单分类";
      } else {
        dict = "宣教分类";
      }
      this.ruleForm = obj;
      let infoId = [];
      this.itemList = [];
      let teamsId = "";
      let doctorId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        doctorId = JSON.parse(localStorage.userInfo).id;
      }
      this.$httpAes({
        url: this.$http.adornUrl("/visitInfo/getVisitInfoByUUID"),
        method: "post",
        data: {
          stringParam1: this.ruleForm.uuid,
          stringParam4: doctorId,
          stringParam3: JSON.parse(localStorage.patientInfo).patience_id,
          stringParam2: teamsId,
        },
        // data: Qs.stringify(obj)
      }).then(({ data }) => {
        if (data.status) {
          this.ruleForm.visitInfos = data.data.visitInfos;
          this.ruleForm.mbglPrescriptionRecords =
            data.data.mbglPrescriptionRecords;
          if (this.ruleForm.visitInfos && this.ruleForm.visitInfos.length) {
            for (let s of this.ruleForm.visitInfos) {
              s.id = s.infoId;
              s.name = s.tableName;
              this.itemList.push(s);
              infoId.push(s.infoId);
            }
            // this.itemList = this.ruleForm.visitInfos
          }
          // if (
          //   this.ruleForm.mbglPrescriptionRecords &&
          //   this.ruleForm.mbglPrescriptionRecords.length
          // ) {
          //   for (let s of this.ruleForm.mbglPrescriptionRecords) {
          //     s.infoId = s.id;
          //     s.id = s.prescriptionType;
          //     if (s.id == 1) {
          //       s.name = "中药处方";
          //     }
          //     if (s.id == 2) {
          //       s.name = "西药/中成药处方";
          //     }
          //     // setTimeout(() => {
          //     //   that.$refs.tree.setChecked2(s);
          //     // }, 1000);

          //     this.itemList.push(s);
          //   }
          // }
          let newArr = [];
          if (obj.prescriptionType && obj.prescriptionType.indexOf("1") > -1) {
            let newObj = {
              id: 1,
              name: "中药处方",
            };
            newArr.push(newObj);
          }
          if (obj.prescriptionType && obj.prescriptionType.indexOf("2") > -1) {
            let newObj2 = {
              id: 2,
              name: "西药/中成药处方",
            };
            newArr.push(newObj2);
          }
          this.itemList = [...this.itemList, ...newArr];
          console.log("this.itemList", this.itemList);
          this.$nextTick(() => {
            this.$refs.tree.initExpand();
          });
        }
      });
    },
    async confirm() {
      console.log(this.item, this.item[1]);
      let item = this.itemList.filter((v) => v.id == this.item)[0];
      console.log(item, "item");
      if (item) {
        // let obj = {type:this.options.find(v=>v.value==this.item[0]),item:this.itemList.find(v=>v.id===this.item[1])}
        //
        // console.log(obj);
        this.$emit("chooseQs", item);
        this.visible = false;
      } else {
        this.$message.error("请选择！");
      }
    },
  },
  // watch:{
  //   'this.type':function(value){
  //     console.log(value,'value watch');
  //     this.initType = value
  //   }
  // }
};
</script>
<style lang="scss">
.addvisit {
  .el-dialog {
    width: 960px !important;
    height: 660px !important;
    margin-top: 10vh !important;
  }
  .el-dialog__footer {
    border: 0 !important;
    padding-bottom: 0;
    padding-top: 10px;
  }
  .qxzyfs {
    height: 20px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin: 19px;
  }
  .el-dialog__body {
    padding: 0px 20px;
  }
  /deep/.el-cascader-menu {
    width: 50%;
  }
  .title {
    width: 100%;
    display: flex;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border: 1px solid #cccccc;
    .name {
      width: 50%;
    }
  }
  .itemList {
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
  }
  .left {
    border: 1px solid #ccc;
    border-top: 0;
    overflow: auto;
    // scrollbar-width: none;
    // -ms-overflow-style: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
  .right {
    height: 410px;
    // border:1px solid #ccc;border-left:none;
    border: 1px solid #eeeeee !important;
  }
}
</style>
