<template>
  <div class="agreement-form">
    <el-dialog title="协定方" :visible.sync="visible" width="1200">
      <div class="search-name">
        <el-input
          placeholder="输入协定方名称搜索"
          v-model="searchValue"
          class="input-with-select"
        >
          <el-button
            slot="append"
            @click="getList"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </div>
      <el-table
        :data="dataList"
        v-loading="loading"
        :key="itemKey"
        @cell-mouse-enter="mouseEnter"
        @cell-mouse-leave="mouseLeave"
      >
        <el-table-column
          prop="agreementRecordsName"
          label="协定方名称"
          width="250"
        >
          <template slot-scope="scope"
            >{{ scope.row.agreementRecordsName
            }}<img
              v-show="(scope.row.ownerCreate || isEdit) && showEditBtn && rowId == scope.row.id"
              class="edit-btn"
              src="@/assets/img/case/edit.png"
              @click="showAddGreement(scope.row)"
          /></template>
        </el-table-column>
        <el-table-column prop="drugsNames" label="详情"></el-table-column>
        <el-table-column
          prop="createName"
          label="创建人"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          width="200"
        ></el-table-column>
        <el-table-column prop="docterName" label="开放范围" width="140">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">团队内共享</span>
            <span v-if="scope.row.type == 2">仅机构内共享</span>
            <span v-if="scope.row.type == 3">仅自己使用</span>
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          label="操作"
          width="100"
          fixed="right"
          align="right"
          header-align="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              style="color: #009cff"
              v-if="scope.row.checked == false"
              @click="checkChose(scope.row)"
              >选择</el-button
            >
            <el-button
              type="text"
              style="color: #19d901"
              v-else
              @click="checkChose(scope.row, scope.$index)"
              >已选</el-button
            >
            <el-button
              type="text"
              style="color: #dd0606"
              @click="deleteRecord(scope.row)"
              v-if="scope.row.ownerCreate || isEdit"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="importData">导 入</el-button>
        <el-button @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
    <add-agreement
      v-if="addAgreementDialog"
      ref="addAgreement"
      @refreshData="getList"
    ></add-agreement>
  </div>
</template>

<script>
import qs from "qs";
import addAgreement from "./addGreement";
export default {
  components: {
    addAgreement,
  },
  data() {
    return {
      searchValue: "",
      dataList: [],
      visible: false,
      loading: false,
      itemKey: 0,
      currentChoseList: [], //当前选择协定
      prescriptionRecordInfo: {},
      addAgreementDialog: false,
      isEdit: false, //是否能编辑协定方
      userId: "",
      showEditBtn: false,
      rowId: "",
    };
  },
  methods: {
    init(obj) {
      this.visible = true;

      this.prescriptionRecordInfo = obj;
      this.getUserInfo();
    },
    getList() {
      this.currentChoseList = [];
      let params = {
        intParam1: 10000,
        intParam2: 1,
        stringParam3: this.searchValue,
        stringParam2: localStorage.currentTeam
          ? JSON.parse(localStorage.currentTeam).id
          : "",
      };
      this.loading = true;
      this.$httpAes({
        url: this.$http.adornUrl("/agreementRecords/list"),
        method: "post",
        data: params,
      }).then(({ data }) => {
        if (data.status) {
          this.dataList = data.data;
          this.loading = false;
          if (this.dataList.length) {
            this.dataList.forEach((ele, index) => {
              ele.checked = false;
              ele.index = index;
              let arr = ele.agreementDrugs.map((chr) => {
                return chr.drugsName + `(${chr.drugsDose + chr.drugsUseUnit})`;
              });
              if (arr.length) {
                ele.drugsNames = arr.join("；");
              }
              if (ele.createBy == this.userId) {
                ele.ownerCreate = true;
              }
            });
          }
        }
      });
    },
    // 选择协定方
    checkChose(row, index) {
      this.itemKey++;
      row.checked = !row.checked;
      if (row.checked) {
        this.currentChoseList.push(row);
      }
      if (!row.checked) {
        this.currentChoseList.forEach(ele, (index) => {
          if (ele.id == row.id) {
            this.currentChoseList.splice(index, 1);
          }
        });
      }
      this.currentChoseList = this.currentChoseList.sort((a, b) => {
        return a.index - b.index;
      });
      console.log(this.currentChoseList);
    },
    importData() {
      // let patientInfo = {};
      // let teamsId = "";
      // if (localStorage.patientInfo) {
      //   patientInfo = JSON.parse(localStorage.patientInfo);
      // }
      // if (localStorage.currentTeam) {
      //   teamsId = JSON.parse(localStorage.currentTeam).id;
      // }
      if (!this.currentChoseList.length) {
        this.$message.error("请选择协定方");
        return;
      }
      let ids = this.currentChoseList.map((chr) => {
        return chr.id;
      });
      let params = {
        stringParam2: this.prescriptionRecordInfo.teamsId,
        stringParam3: this.prescriptionRecordInfo.patientId,
        stringParam4: this.prescriptionRecordInfo.visitUuid,
        intParam1: 1,
        stringParam1: ids.length ? ids.join(",") : "",
      };
      console.log(params);
      // return;
      this.$httpAes({
        url: this.$http.adornUrl("/agreementRecords/addRecordsByIds"),
        method: "post",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        data: params,
      }).then(({ data }) => {
        if (data.status) {
          this.$message({
            message: "创建成功",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.visible = false;
              this.$emit("refreshData");
            },
          });
        } else {
          this.$message({
            message: data.msg,
            type: "error",
            duration: 1500,
            onClose: () => {},
          });
        }
      });
    },
    deleteRecord(item) {
      this.$confirm(`确定进行删除操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonClass: "btn-custom-cancel",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          stringParam1: item.id,
        };
        this.$httpAes({
          url: this.$http.adornUrl("/agreementRecords/deleteByIds"),
          method: "post",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          data: params,
        }).then(({ data }) => {
          if (data.status) {
            this.$message({
              message: "删除成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getList();
              },
            });
          } else {
            this.$message({
              message: data.msg,
              type: "error",
              duration: 1500,
              onClose: () => {},
            });
          }
        });
      });
    },
    showAddGreement(item) {
      this.addAgreementDialog = true;
      this.$nextTick(() => {
        this.$refs.addAgreement.init(item, 1);
      });
    },
    // 获取团队信息
    getUserInfo() {
      let that = this;
      that
        .$httpAes({
          url: that.$httpAes.adornUrl("/organBusiness/info"),
          method: "post",
		   data: {},
        })
        .then(({ data }) => {
          if (data.data) {
            this.userId = data.data.id;
            this.getList();
            let currentTeam = JSON.parse(localStorage.currentTeam);
            if (currentTeam.teamsMembers && currentTeam.teamsMembers.length) {
              if (
                currentTeam.teamsMembers[0].roleIds ===
                "e89325119fa411e89e43525400558291"
              ) {
                this.isEdit = true;
              }
            }
          }
        });
    },
    //鼠标移入单元格事件
    mouseEnter(row) {
      this.showEditBtn = true;
      this.rowId = row.id; ////赋值行id，便于页面判断
    },
    //鼠标移出单元格事件
    mouseLeave(row) {
      this.showEditBtn = false;
      this.rowId = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.agreement-form {
  /deep/.el-dialog {
    width: 1200px !important;
  }
  .edit-btn {
    width: 18px;
    margin-left: 8px;
    cursor: pointer;
  }
}
.search-name {
  .input-with-select {
    width: 314px;
  }
  padding-bottom: 24px;
}
</style>
